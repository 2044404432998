import 'tailwindcss/tailwind.css';
import '../styles/global.scss';
import dynamic from 'next/dynamic';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import store from '../utils/store/store';
import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance';
import 'intersection-observer';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { appWithTranslation } from 'next-i18next';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { consentCookieName } from '../components/ConsentDialog/consent-configs';

const ignoredErrors = [
  { name: 'TypeError', messageIncludes: 'Load failed' },
  { name: 'Error', messageIncludes: 'fundingchoicesmessages.google.com' },
  { name: 'Error', messageIncludes: 'boq-content-ads-contributor' },
  { name: 'TypeError', messageIncludes: "undefined is not an object (evaluating 'e.latitude')" },
  { name: 'Error', messageIncludes: "undefined is not an object (evaluating 'e.latitude')" },
  { name: 'ReferenceError', messageIncludes: "Can't find variable: gmo" },
];

function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    window.IntersectionObserver = dynamic(() =>
      import('intersection-observer')
    );
  }
}

function App({ Component, pageProps }) {
  // let ErrorBoundary = ({ children }) => <>{children}</>;
  const [ErrorBoundary, setErrorBoundary] = useState(() => React.Fragment);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    // let cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
    // if (cookieState) {
    //   applyThirdPartyScripts();
    // } else {
    //   window.addEventListener('storage', () => {
    //     cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
    //     if (cookieState) {
    //       applyThirdPartyScripts();
    //     }
    //   });
    // }

    // Load functionality related scripts
    applyThirdPartyScripts();
    let cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
    const consetCookie = document.cookie.split('; ').find((row) => row.startsWith(consentCookieName));
    const consentFromCookie = consetCookie && JSON.parse(consetCookie.split('=')[1]);
    if (cookieState) {
      const consentData = JSON.parse(localStorage.getItem(consentCookieName)) || consentFromCookie || {};
      console.log('Saved consentData', consentData);
      applyConsentScripts(consentData);
      // const storedConsent = JSON.parse(localStorage.getItem('BC-Consent-Cookie') || '{}');

      // if (Object.keys(storedConsent).length > 0) {
      //   // Re-send consent preferences if they exist
      //   window.dataLayer = window.dataLayer || [];
      //   window.dataLayer.push({
      //     event: 'gtm_consent_update',
      //     consent_preferences: storedConsent,
      //   });
      // }
    } else {
      window.addEventListener('storage', () => {
        cookieState = JSON.parse(localStorage.getItem('bcCookieConsentShowed'));
        if (cookieState) {
          const consentData = JSON.parse(localStorage.getItem(consentCookieName)) || consentFromCookie || {};
          console.log('Saved consentData', consentData);
          applyConsentScripts(consentData);
        }
      });
    }
    
  }, []);

  const applyConsentScripts = (consentData) => {
    if (consentData.analytics) {
      hotjar.initialize(2676165, 6);
    }
    if (consentData.marketing) {
      if (process.env.pixelId) {
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init(process.env.pixelId);
      }
    }
    if (consentData.ad_user_data || consentData.ad_personalization) {
      // loadPersonalizationScripts(); // Load any personalization-related scripts
    }
  }
  const applyThirdPartyScripts = () => {
    if (process.env.bugsnagApiKey) {
      BugsnagPerformance.start({
        apiKey: process.env.bugsnagApiKey,
      });
      const bugsnagClient = Bugsnag.start({
        apiKey: process.env.bugsnagApiKey,
        plugins: [new BugsnagPluginReact()],
        onError: (event) => {
          const shouldIgnore = ignoredErrors.some(ignored =>
            event.errors.some(err =>
              err.errorClass === ignored.name &&
              err.errorMessage.toLowerCase().includes(ignored.messageIncludes.toLowerCase())
            )
          );
      
          // If the error should be ignored, prevent it from being reported
          if (shouldIgnore) {
            return false; // Stop sending to Bugsnag
          }
      
          return true; // Allow other errors to be reported
        }
      });
      // ErrorBoundary = bugsnagClient.getPlugin('react')?.createErrorBoundary(React);
      const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')?.createErrorBoundary(React);
      //setErrorBoundary(() => (props) => (
      //   <BugsnagErrorBoundary FallbackComponent={<h2>Something went wrong. Please try again later.</h2>}>
      //     {props.children}
      //   </BugsnagErrorBoundary>
      // ));
      setErrorBoundary(() => {
        const ErrorBoundaryComponent = ({ children }) => (
          <BugsnagErrorBoundary onError={() => {
            setIsError(true); // Show dialog when an error is caught
          }}>
            {children}
          </BugsnagErrorBoundary>
        );

        return ErrorBoundaryComponent;
      });
    }
    TagManager.initialize({
      gtmId: process.env.gtm,
    });
    loadPolyfills();
  };

  return (
    <>
      <Provider store={store}>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Provider>
      
      <Dialog
        open={isError}
        className='m-auto lg:w-1/3 lg:h-1/3 w-4/5 text-center'
      >
        <div className='p-7'>
          <h3 className='font-libre'>Something went wrong...</h3>
          <p className='my-4'>Sorry for the inconvenience. Please try again later.</p>

          <div className='flex flex-col lg:flex-row justify-between'>
            <Button
              variant="contained"
              type="button"
              color="primary"
              className='mb-3 lg:mb-0'
              sx={{ textTransform: 'unset', backgroundColor: '#3C6562' }}
              onClick={() => {
                setIsError(false);
                window.location.reload();
              
              }}
            >
              Refresh page
            </Button>
            <Button
              variant="contained"
              type="button"
              color="primary"
              className='mb-3 lg:mb-0'
              sx={{ textTransform: 'unset', backgroundColor: '#3C6562' }}
              onClick={() => {
                setIsError(false);
                window.location.href = '/';
              }}
            >
              Back to main page
            </Button>
          </div>
        </div>
      </Dialog>

    </>
  );
}
export default appWithTranslation(App);
