export const consentCookieName = 'BC_consent';

export const categotiesData = {
    general: {
      title: 'Neccessary',
      description: ' Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
      required: true
    },
    functional: {
      title: 'Functional',
      description: 'These cookies are used to enhance user experience by remembering preferences and providing insights into site usage such as your language preference or location.',
      required: true
    },
    // security: {
    //   title: 'Security',
    //   description: 'Enables storage related to security such as authentication functionality, fraud prevention, and other user protection',
    //   required: true
    // },
    analytics: {
      title: 'Analytics',
      description: 'These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.',
    },
    marketing: {
      title: 'Marketing',
      description: 'Marketing cookies are used to track visitors across websites to display relevant ads.',
    },
    personalization: {
      title: 'Advertising and Personalization',
      description: 'Personalization cookies allow us to tailor content to your interests. They prevent the same ad from continuously reappearing, ensure that ads are properly displayed for advertisers, and in some cases select advertisements that are based on your interests',
    },
    data_sharing: {
      title: 'Data Sharing',
      description: 'We and our advertising partners use cookies and similar technologies to show you advertising personalized to your interests (known as "personalized advertising") and measure its effectiveness. These technologies collect information such as advertising identifiers, IP addresses, and other unique identifiers from your devices. BusinessClass.com does not sell this data in exchange for money but this sharing of information with partners for personalized advertising purposes may be considered either a “sale” or “share” of personal information under the California Consumer Privacy Act (CCPA) or additional state laws. The use of personalized advertising may also qualify as “targeted advertising” under other U.S. state laws. If you are resident of California, Colorado, Connecticut, Montana, Oregon, Texas, Utah or Virginia (collectively, “Applicable States”), you can opt out of personalized advertising,',
    }
  };

export const regionSpecificCategories = {
    GDPR: ['analytics', 'marketing', 'personalization'],
    CCPA: ['data_sharing', 'marketing'],
    CPRA: ['data_sharing', 'marketing', 'personalization'],
    CDPA: ['data_sharing', 'marketing', 'analytics'],
    CPA: ['data_sharing', 'marketing', 'analytics'],
    CTDPA: ['data_sharing', 'marketing', 'analytics'],
    UCPA: ['data_sharing', 'marketing', 'analytics'],
    LGPD: ['analytics', 'marketing', 'personalization'],
    PIPEDA: ['analytics', 'marketing', 'personalization'],
    'Quebec Law 25': [
      'data_sharing',
      'analytics',
      'marketing',
      'personalization',
    ],
    'Australia Privacy Act': ['analytics', 'marketing'],
    'PDPA Singapore': ['analytics', 'marketing'],
    'PDPA Thailand': ['analytics', 'marketing'],
    'PDPL Saudi Arabia': ['analytics', 'marketing'],
    POPIA: ['analytics', 'marketing'],
    default: [], // Only necessary cookies
  };

export const privacyRegions = {
    GDPR: ['FR', 'DE', 'GB', 'NO', 'IS', 'LI', 'CH', 'IT', 'ES', 'SE', 'NL', 'BE', 'FI', 'DK', 'IE', 'PT', 'GR', 'AT', 'CY', 'LU', 'MT', 'EE', 'LV', 'LT', 'PL', 'CZ', 'SK', 'SI', 'HU', 'RO', 'BG'],
    CCPA: ['US-CO', 'US-CT', 'US-MT', 'US-OR', 'US-TX', 'US-UT', 'US-VA'], 
    CPRA: ['US-CA'], // CPRA also applies to California
    CDPA: ['US-VA'], // Virginia, USA
    CPA: ['US-CO'], // Colorado, USA
    CTDPA: ['US-CT'], // Connecticut, USA
    UCPA: ['US-UT'], // Utah, USA
    LGPD: ['BR'], // Brazil
    PIPEDA: ['CA'], // Canada
    'Quebec Law 25': ['CA-QC'], // Quebec, Canada
    'Australia Privacy Act': ['AU'], // Australia
    'PDPA Singapore': ['SG'], // Singapore
    'PDPA Thailand': ['TH'], // Thailand
    'PDPL Saudi Arabia': ['SA'], // Saudi Arabia
    POPIA: ['ZA'], // South Africa
  };

export const countryWithRegions = ['US', 'CA'];
